import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css'; // External CSS for styling

const LandingPage = () => {
    return (
        <div className="landing-page">
            <div className="button-container">
                <Link to="/finder" className="custom-button">
                    Potential Finder
                </Link>
                <Link to="/effect" className="custom-button">
                    Effect Planning
                </Link>
                <a
                    href="https://app.powerbi.com/groups/816ba5ee-5a3e-49a9-a4c0-a69d1f626333/reports/40472567-baba-4a29-8f80-b911b5bf0001/ReportSection89dedebcbdea2ab40b80?bookmarkGuid=Bookmark2d5dbd752ddd4e000146"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="custom-button"
                >
                    Target Steering
                </a>
                <a
                    href="https://app.powerbi.com/groups/5bb4f8bd-ab20-4ed0-8d07-653ca0c52e68/reports/6613cc16-c9af-4d2f-a82c-2533d1b245a7/ReportSectionc96c6f83aede47276b90?experience=power-bi"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="custom-button"
                >
                    TPT Analytics
                </a>
                <a
                    href="https://app.powerbi.com/groups/816ba5ee-5a3e-49a9-a4c0-a69d1f626333/reports/37ea9fa4-d255-403d-8902-774dba0644f8/ReportSection543523c8149543628f23"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="custom-button"
                >
                    Alert & Analysis
                </a>
            </div>
        </div>
    );
};

export default LandingPage;